import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { PurchaseService } from '../../services/purchase.service';
import { AnalyticsService } from '../../services/analytics.service';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ModalService } from '../../services/modal.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-buy-section',
  templateUrl: './buy-section.component.html',
  styleUrls: ['./buy-section.component.scss'],
})
export class BuySectionComponent implements OnInit, OnDestroy {
  // permissions not needed for consumables
  @Input()
  get permissions(): any {
    return this._permissions;
  }
  set permissions(value) {
    this._permissions = value;
    this.dealAvailable = this.checkDealAvailable();
  }
  _permissions: any;

  @Input() type: "consumables" | "decks" | "journals" | "avatars" | "specials";
  @Input() cssClass: string;
  @Input() horizontal: boolean;
  @Input() paidAvatars: any = {};
  @Input() user: any;

  loading: boolean = true;
  allPurchases: any;
  offerings: any = [];
  consumables: any = [];
  decks: any = [];
  journals: any = [];
  specials: any = [];
  url: any = this.route.url;
  production: boolean;
  save: any = {
    consumables: [0, 50, 63],
    journals: [0, 17, 20]
  };
  minutes: any = [
    (33 / environment.callCost).toFixed(1),
    (333 / environment.callCost).toFixed(1),
    (777 / environment.callCost).toFixed(1)
  ];
  dealAvailable: boolean = this.checkDealAvailable();
  interval: any;
  countdown: any = {
    dd: 0,
    hh: 0,
    mm: 0,
    ss: 0
  };
  web: boolean = Capacitor.getPlatform() == 'web';

  constructor(
    private analyticsService: AnalyticsService,
    private purchaseService: PurchaseService,
    private modalService: ModalService,
    private route: Router,
    private iab: InAppBrowser,
  ) {
    if (environment.production) {
      this.production = true;
    };

    setTimeout(() => {
      console.log('open paywall', this.type, this.url);
      this.analyticsService.logEvent('paywall', {
        content_id: this.type,
        source: this.url
      });
      this.getOfferings(this.type);
      console.log(this.offerings, this.type);
    }, 2000);
  }

  openURL(url) {
    const browser = this.iab.create(url, '_system');
    this.analyticsService.logEvent('visit_website', {
      content_type: 'terms',
      content_id: url
    });
  }

  checkOwned(sku: any) {
    var identifier = this.web ? sku.rcBillingProduct.identifier : sku.product.identifier;

    if (this.permissions && this.permissions[this.type]) {
      if (this.permissions[this.type].indexOf(identifier) == -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  // when opening a new modal, it does not get the new offerings automatically
  async getOfferings(offeringId: string) {
    this.purchaseService.getOfferings().subscribe((res) => {
      if (res) {
        this.offerings = res[offeringId].availablePackages;
      }
		});
  }

  async purchase(sku: any) {
    var owned = this.checkOwned(sku);
    if (!this.user.email) {
      this.modalService.needsAccount('credits');
    } else {
      if (!owned) {
        this.analyticsService.logEvent('start_purchase', {
          content_id: 'credits',
          sku: sku.skuId,
          source: this.url
        });

        if (this.web) {
          await this.purchaseService.purchaseWeb(sku);
        } else {
          await this.purchaseService.purchase(sku);
        }
      }
    }
	}

	async restore() {
		await this.purchaseService.restore();
	}

  checkDealAvailable() {
    var owned = this.permissions?.journals?.indexOf('journal_265') > -1;
    var expired = this.purchaseService.checkDealExpired();
    // console.log('owned deal?', owned, 'expired deal?', expired);
    if (owned || expired) {
      return false;
      clearInterval(this.interval);
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit() {
    this.purchaseService.checkDealEligibility().then(() => {
      this.countdown = this.purchaseService.setCountdown();
      this.interval = setInterval(() => {
        this.countdown = this.purchaseService.setCountdown();
      }, 1000);
    });
  }
}
