import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { User } from '../user';
import { UserService } from './user.service';
import { Subject } from 'rxjs';
import tinycolor from "tinycolor2";

@Injectable({
  providedIn: 'root'
})

export class ProductService {
  constructor(
    private userService: UserService
  ) {
    this.userService.getUser().then(data  => {
      this.user = data;
    });
    this.userUpdates = this.userService.getSubscription('userSubscription').subscribe((data) => {
      this.user = data;
    });
  }

  offerings: any;
  user: User;
  userUpdates = new Subject<any>();
  products: any = [
    {
      name: 'Tarot Decks',
      id: 'tarot',
      items: [
        {
          id: 'SSTRWS',
          name: 'Seventh Sphere Tarot RWS',
          url: 'https://labyrinthos.co/products/seventh-sphere-rider-waite-smith-tarot-deck',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#727a90',
            containerBgGradient: '#586b83',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'TVM',
          name: 'Tarot of the Velvet Moon',
          url: 'https://labyrinthos.co/products/tarot-of-the-velvet-moon',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#15352d',
            containerBgGradient: '#122823',
            boxW: 70,
            boxH: 103,
            fan: ['major-07.png', 'pentacles-70.png', 'swords-54.png', 'wands-30.png', 'cups-51.png']
          }
        },
        {
          id: 'AIS',
          name: 'Arcana Iris Sacra',
          url: 'https://labyrinthos.co/collections/all/products/arcana-iris-sacra-tarot-deck-cards',
          category: 'tarot',
          type: 'Thoth Tradition',
          reversals: false,
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#8c1013',
            containerBgGradient: '#5b0b1a',
            boxW: 70,
            boxH: 150,
            fan: ['major-18.png', 'major-20.png', 'major-17.png', 'major-12.png', 'major-23.png']
          }
        },
        {
          id: 'GTT',
          name: 'Golden Thread Tarot',
          url: 'https://labyrinthos.co/collections/all/products/golden-thread-tarot-deck-cards',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#b89661',
            containerBgGradient: '#a37b45',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'LST',
          name: 'Luminous Spirit Tarot',
          url: 'https://labyrinthos.co/collections/all/products/luminous-spirit-tarot-deck-holographic-tarot-cards',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#c6e8eb',
            containerBgGradient: '#e7c4de',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'SST',
          name: 'Seventh Sphere Tarot de Marseille',
          url: 'https://labyrinthos.co/collections/all/products/seventh-sphere-tarot-deck-modern-tarot-de-marseille-rose-gold',
          category: 'tarot',
          type: 'Marseille Tradition',
          reversals: false,
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#bbe0d0',
            containerBgGradient: '#9ecbcc',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'RWS1910',
          name: '1910 Rider Waite Smith',
          url: 'https://labyrinthos.co/products/golden-universal-tarot-deck-rider-waite-smith',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: true,
          creator: ['Pamela Coleman Smith'],
          deckView: {
            containerBg: '#eed74b',
            containerBgGradient: '#bdaf55',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'TDM_LABY',
          name: '1760 Tarot de Marseille Recolor',
          category: 'tarot',
          url: 'https://labyrinthos.co/collections/all',
          type: 'Marseille Tradition',
          reversals: false,
          web: true,
          creator: ['Nicolas Conver'],
          deckView: {
            containerBg: '#92ADAE',
            containerBgGradient: '#719999',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'LS_MCT',
          name: 'Marseille Cat Tarot',
          category: 'tarot',
          url: 'https://labyrinthos.co/products/marseille-cat-tarot',
          type: 'Marseille Tradition',
          reversals: false,
          web: false,
          creator: ['Severino Baraldi'],
          publisher: 'scarabeo',
          deckView: {
            containerBg: '#f9cdc0',
            containerBgGradient: '#e2a698',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'LS_SMT',
          name: 'Santa Muerte Tarot',
          url: 'https://labyrinthos.co/products/santa-muerte-tarot',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: false,
          creator: ['Fabio Listrani'],
          publisher: 'scarabeo',
          deckView: {
            containerBg: '#8c1013',
            containerBgGradient: '#5b0b1a',
            boxW: 70,
            boxH: 120,
            fan: ['major-17.png', 'wands-30.png', 'pentacles-75.png', 'pentacles-70.png', 'wands-35.png']
          }
        },
        {
          id: 'LS_1001',
          name: 'Tarot of the Thousand and One Nights',
          url: 'https://www.loscarabeo.com/en/products/i-tarocchi-delle-mille-e-una-notte',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: false,
          creator: ['Leon Carre'],
          publisher: 'scarabeo',
          deckView: {
            containerBg: '#4d602f',
            containerBgGradient: '#919f85',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'LS_TM',
          name: 'Tarot Mucha',
          url: 'https://www.loscarabeo.com/en/products/mucha-tarot',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: false,
          creator: ['Giulia Massaglia', 'Barbara Nosenzo'],
          publisher: 'scarabeo',
          deckView: {
            containerBg: '#e5bd63',
            containerBgGradient: '#a75035',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'LS_VT',
          name: 'Visconti-Sforza',
          url: 'https://www.loscarabeo.com/en/products/i-tarocchi-dei-visconti',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: false,
          creator: ['Bonifacio Bembo', 'A.A. Atanassov'],
          publisher: 'scarabeo',
          deckView: {
            containerBg: '#2f343b',
            containerBgGradient: '#2f343b',
            boxW: 70,
            boxH: 120,
            fan: ['major-12.png', 'cups-50.png', 'wands-26.png', 'swords-55.png', 'pentacles-77.png']
          }
        },
        {
          id: 'LS_TB',
          name: 'Tarot Bottecelli',
          url: 'https://www.loscarabeo.com/en/products/i-tarocchi-dorati-di-botticelli',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: false,
          creator: ['Atanas A. Atanassov'],
          publisher: 'scarabeo',
          deckView: {
            containerBg: '#2f343b',
            containerBgGradient: '#2f343b',
            boxW: 70,
            boxH: 120,
            fan: ['cups-43.png', 'major-19.png', 'pentacles-70.png', 'swords-61.png', 'major-08.png']
          }
        },
        {
          id: 'LS_RT',
          name: 'Romantic Tarot',
          url: 'https://www.loscarabeo.com/en/products/romantic-tarot',
          category: 'tarot',
          type: 'Rider Waite Smith Tradition',
          reversals: true,
          web: false,
          creator: ['Giulia Massaglia', 'Emanuela Signorini'],
          publisher: 'scarabeo',
          deckView: {
            containerBg: '#ACBEBD',
            containerBgGradient: '#7B999F',
            boxW: 70,
            boxH: 120,
            fan: ['cups-43.png', 'major-19.png', 'pentacles-70.png', 'swords-61.png', 'major-08.png']
          }
        },
      ]
    },
    {
      name: 'Lenormand Decks',
      id: 'lenormand',
      items: [
        {
          id: 'GTL',
          name: 'Golden Thread Lenormand',
          url: 'https://labyrinthos.co/collections/all/products/golden-thread-lenormand-deck',
          category: 'lenormand',
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#b89661',
            containerBgGradient: '#a37b45',
            boxW: 70,
            boxH: 120,
            fan: ['lenormand-02.png', 'lenormand-07.png', 'lenormand-15.png', 'lenormand-23.png', 'lenormand-32.png']
          }
        },
        {
          id: 'SSL',
          name: 'Seventh Sphere Lenormand',
          url: 'https://labyrinthos.co/collections/all/products/seventh-sphere-lenormand-deck',
          category: 'lenormand',
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#b9e9d5',
            containerBgGradient: '#9dcccb',
            boxW: 70,
            boxH: 120,
            fan: ['lenormand-33.png', 'lenormand-30.png', 'lenormand-16.png', 'lenormand-12.png', 'lenormand-02.png']
          }
        },
        {
          id: 'ALDIS',
          name: 'Arcana Lenormand de Iris Sacra',
          url: 'https://labyrinthos.co/collections/all/products/arcana-iris-sacra-lenormand-deck',
          category: 'lenormand',
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#8c1013',
            containerBgGradient: '#5b0b1a',
            boxW: 70,
            boxH: 120,
            fan: ['lenormand-15.png', 'lenormand-18.png', 'lenormand-22.png', 'lenormand-24.png', 'lenormand-27.png']
          }
        }
      ]
    },
    {
      name: 'Astrology Oracle Decks',
      id: 'astro',
      items: [
        {
          id: 'CADIS',
          name: 'Claves Astrologicae de Iris Sacra',
          url: 'https://labyrinthos.co/products/claves-astrologicae-astrology-oracle-deck',
          category: 'astro',
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#8c1013',
            containerBgGradient: '#5b0b1a',
            boxW: 70,
            boxH: 120,
            fan: ['astrology-oracle_zodiac-capricorn.png', 'astrology-oracle_zodiac-libra.png', 'astrology-oracle_zodiac-aries.png', 'astrology-oracle_planet-moon.png', 'astrology-oracle_zodiac-scorpio.png']
          }
        },
        {
          id: 'SSAO',
          name: 'Seventh Sphere Astrology Oracle',
          url: 'https://labyrinthos.co/collections/all/products/seventh-sphere-astrology-oracle',
          category: 'astro',
          web: true,
          creator: ['Tina Gong'],
          publisher: 'labyrinthos',
          deckView: {
            containerBg: '#48506b',
            containerBgGradient: '#434256',
            boxW: 70,
            boxH: 120,
            fan: ['astrology-oracle_house-02.png', 'astrology-oracle_house-05.png', 'astrology-oracle_planet-saturn.png', 'astrology-oracle_house-11.png', 'astrology-oracle_house-07.png']
          }
        }
      ]
    },
    {
      name: 'Rune Sets',
      id: 'rune',
      items: [
        {
          id: 'rock',
          name: 'Stone',
          publisher: 'labyrinthos',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#aaaaaa',
            containerBgGradient: '#222222',
          }
        },
        {
          id: 'roseQuartz',
          name: 'Rose Quartz',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#E1C2CE',
            containerBgGradient: '#A96D9C',
          }
        },
        {
          id: 'amethyst',
          name: 'Amethyst',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#9379A6',
            containerBgGradient: '#2C1140',
          }
        },
        {
          id: 'lapis',
          name: 'Lapis Lazuli',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#302E91',
            containerBgGradient: '#1C1B49',
          }
        },
        {
          id: 'snowflakeObsidian',
          name: 'Snowflake Obsidian',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#4D4D4D',
            containerBgGradient: '#222222',
          }
        },
        {
          id: 'malachite',
          name: 'Malachite',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#1E5F49',
            containerBgGradient: '#083E18',
          }
        },
        {
          id: 'opalite',
          name: 'Opalite',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#D7FCFF',
            containerBgGradient: '#D9ADF1',
          }
        },
        {
          id: 'citrine',
          name: 'Citrine',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#E4A14E',
            containerBgGradient: '#C26929',
          }
        },
        {
          id: 'jade',
          name: 'Jade',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#A5CF2D',
            containerBgGradient: '#888B08',
          }
        },
        {
          id: 'sunstone',
          name: 'Sunstone',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#E8E8E7',
            containerBgGradient: '#D0D0CB',
          }
        },
        {
          id: 'blueLaceAgate',
          name: 'Blue Lace Agate',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#87A8D1',
            containerBgGradient: '#2C5E99',
          }
        },
        {
          id: 'opal',
          name: 'Opal',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#C9E5B0',
            containerBgGradient: '#7DC3B9',
          }
        },
        {
          id: 'labradorite',
          name: 'Labradorite',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#2D4254',
            containerBgGradient: '#031824',
          }
        },
        {
          id: 'jasper',
          name: 'Jasper',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#8E3D1E',
            containerBgGradient: '#571F0A',
          }
        },
        {
          id: 'mossAgate',
          name: 'Moss Agate',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#386120',
            containerBgGradient: '#334803',
          }
        },
        {
          id: 'fluorite',
          name: 'Fluorite',
          publisher: 'labyrinthos_premium',
          url: 'https://labyrinthos.co/collections/all/products/runes',
          category: 'rune',
          web: true,
          deckView: {
            containerBg: '#7eccc0',
            containerBgGradient: '#7a497f',
          }
        },
      ]
    }
  ]

  lighten(color: string, percent: number) {
    return tinycolor(color).lighten(percent).toString();
  }

  darken(color: string, percent: number) {
    return tinycolor(color).darken(percent).toString();
  }

  getProducts() {
    return this.products;
  }

  filterNotOwnedDecks(decksToFilter: Array<any>) {
    // filter any list of decks and return only the ones that you DON'T own
    var filter = [];
    for (let deck of decksToFilter) {
      var laby = deck.publisher == 'labyrinthos';
      if (this.user?.permissions?.decks) {
        var notOwned = this.user.permissions.decks.indexOf(deck.id) == -1;
      };

      if (notOwned && !laby) {
        filter.push(deck);
      }
    }
    return filter;
  }

  filterOwnedDecks(decksToFilter: Array<any>) {
    // filter any list of decks and return only the ones that you DO own
    var filter = [];
    for (let deck of decksToFilter) {
      var laby = deck.publisher == 'labyrinthos';
      if (this.user?.permissions?.decks) {
        var owned = this.user.permissions.decks.indexOf(deck.id) > -1;
      };

      if (owned || laby) {
        filter.push(deck);
      };
    }
    return filter;
  }

  getOwnedDecks() {
    // all labyrinthos decks as objects
    var decks = this.searchProducts('publisher', 'labyrinthos');
    // plus add the decks that exist in this list, whose id is in the user.permissions.decks array.
    if (this.user?.permissions?.decks) {
      for (let deckId of this.user.permissions.decks) {
        decks.push(this.searchProducts('id', deckId)[0]);
      };
    }
    return decks;
  }

  getOwnedDecksByType(typeId: string) {
    // get all decks of type and check if it either is labyrinthos or in permissions
    var typeDecks = this.getProductsByType(typeId).items;
    if (typeId == 'rune' && this.user?.permissions?.features.includes('premium')) {
      return typeDecks;
    } else {
      return this.filterOwnedDecks(typeDecks);
    }
  }

  getPurchaseableDecksByType(typeId: string) {
    // get all decks of type and check if it either is labyrinthos or in permissions
    var typeDecks = this.getProductsByType(typeId).items;
    return this.filterNotOwnedDecks(typeDecks);
  }

  getProductsByType(typeId) {
    return this.products.filter((type) => {
      return type.id == typeId;
    })[0];
  }

  getFullTitle(typeId, itemId) {
    return this.products.filter((type) => {
      return type.id == typeId;
    })[0].items?.filter((item) => {
      console.info(typeId, itemId, item);

      return item.id == itemId;
    })[0].name;
  }

  getDeck(typeId: string, itemId: string) {
    return this.products.filter((type) => {
      return type.id == typeId;
    })[0].items.filter((item) => {
      return item.id == itemId;
    })[0];
  }

  getProductType(itemId: string) {
    for (let type of this.products) {
      for (let item of type.items) {
        if (itemId == item.id) {
          return type.id;
        }
      }
    }
  }

  removeRuneDecks(arr: Array<string>) {
    var runes = this.getProductsByType('rune').items;
    runes = runes.map(r => r.id);
    var nonRunes = arr.filter(id => !runes.includes(id));
    return nonRunes;
  }

  searchProducts(field: string, searchTerm: string) {
    if (searchTerm) {
      var all = this.products.map((category) => {
        return category.items;
      }).flat(1);

      return all.filter((item) => {
        if (item[field]) {
          return item[field].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }
      });
    } else {
      return [];
    }
  }
}
