import { Injectable } from '@angular/core';
import { User, UserExtras } from '../user';
import { LessonService } from './lesson.service';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ToastController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Device } from '@capacitor/device';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(
    private storage: Storage,
    private httpClient: HttpClient,
    private lessonService: LessonService,
    private toastController: ToastController,
    private modalController: ModalController,
    private router: Router,
  ) {
    this.init();
  }
  url = environment.labyrinthosAPI;
  defaultUser: User = {
    name: 'Nameless Ghost',
    tarotDeck: 'SSTRWS',
    lenormandDeck: 'SSL',
    astroDeck: 'CADIS',
    runeDeck: 'rock',
    reversals: true,
    level: 1,
    img: 'avatar_0.png',
    title: 'Fool',
    exp: 0,
    lessons: [],
    credits: 33,
    user_data: {},
    permissions: {
      avatars: [],
      features: [],
      decks: [],
      journals: []
    }
  };
  possibleUserData = [
    'birthdate',
    'score',
    'favoriteSpreads',
    'pronouns',
    'relationship',
    'job',
    'industry',
    'runeDecks',
    'monthlyCalls'
  ];
  user: User;
  userSubscription = new Subject<any>();
  uuid: any;
  titles: any = ['Fool', 'Magician', 'Priestess', 'Empress', 'Emperor', 'Hierophant', 'Lovers', 'Chariot', 'Strength', 'Hermit', 'Wheel', 'Justice', 'Hanged Man', 'Death', 'Temperance', 'Devil', 'Tower', 'Star', 'Moon', 'Sun', 'Judgement', 'Universe'];
  expLevels: any = [0, 20, 50, 90, 140, 200, 270, 350, 440, 540, 650, 770, 900, 1040, 1190, 1350, 1520, 1700, 1890, 2090, 2300, 2520];
  httpOptions: any = {
    headers: new HttpHeaders()
  };

  avatarPacks: Array<any> = [
    {
      id: 'avatar_pack_birthcards',
      text: 'Tarot Birthcards Pack',
      avatars: [
        {img: 'avatar_birthcards_10-1.png', title: 'Wheel, Magician'},
        {img: 'avatar_birthcards_11-2.png', title: 'Justice, High Priestess'},
        {img: 'avatar_birthcards_12-3.png', title: 'Hanged Man, Empress'},
        {img: 'avatar_birthcards_13-4.png', title: 'Death, Emperor'},
        {img: 'avatar_birthcards_14-5.png', title: 'Temperance, Hierophant'},
        {img: 'avatar_birthcards_15-6.png', title: 'Devil, Lovers'},
        {img: 'avatar_birthcards_16-7.png', title: 'Tower, Chariot'},
        {img: 'avatar_birthcards_17-8.png', title: 'Star, Strength'},
        {img: 'avatar_birthcards_18-9.png', title: 'Moon, Hermit'},
        {img: 'avatar_birthcards_19.png', title: 'Sun, Wheel, Magician'},
        {img: 'avatar_birthcards_20-2.png', title: 'Judgement, High Priestess'},
        {img: 'avatar_birthcards_21-3.png', title: 'World, Empress'}
      ]
    },
    {
      id: 'avatar_pack_elements',
      text: 'Elements and Suits Pack',
      avatars: [
        {img: 'avatar_elements_fire.png', title: 'Fire Elemental'},
        {img: 'avatar_elements_water.png', title: 'Water Elemental'},
        {img: 'avatar_elements_air.png', title: 'Air Elemental'},
        {img: 'avatar_elements_earth.png', title: 'Earth Elemental'},
        {img: 'avatar_elements_wands.png', title: 'Ace of Wands'},
        {img: 'avatar_elements_cups.png', title: 'Ace Cups'},
        {img: 'avatar_elements_swords.png', title: 'Ace of Swords'},
        {img: 'avatar_elements_pentacles.png', title: 'Ace of Pentacles'},
      ]
    },
    {
      id: 'avatar_pack_court',
      text: 'Court Cards Pack',
      avatars: [
        {img: 'avatar_court_cups-38.png', title: 'Page of Cups'},
        {img: 'avatar_court_cups-39.png', title: 'Knight of Cups'},
        {img: 'avatar_court_cups-40.png', title: 'Queen of Cups'},
        {img: 'avatar_court_cups-41.png', title: 'King of Cups'},
        {img: 'avatar_court_pentacles-66.png', title: 'Page of Pentacles'},
        {img: 'avatar_court_pentacles-67.png', title: 'Knight of Pentacles'},
        {img: 'avatar_court_pentacles-68.png', title: 'Queen of Pentacles'},
        {img: 'avatar_court_pentacles-69.png', title: 'King of Pentacles'},
        {img: 'avatar_court_swords-52.png', title: 'Page of Swords'},
        {img: 'avatar_court_swords-53.png', title: 'Knight of Swords'},
        {img: 'avatar_court_swords-54.png', title: 'Queen of Swords'},
        {img: 'avatar_court_swords-55.png', title: 'King of Swords'},
        {img: 'avatar_court_wands-24.png', title: 'Page of Wands'},
        {img: 'avatar_court_wands-25.png', title: 'Knight of Wands'},
        {img: 'avatar_court_wands-26.png', title: 'Queen of Wands'},
        {img: 'avatar_court_wands-27.png', title: 'King of Wands'}
      ]
    },
    {
      id: 'avatar_pack_starter',
      text: 'Exclusive Starter Pack Avatars',
      avatars: [
        {img: 'avatar_starter_party.png', title: 'Merrymaker'},
        {img: 'avatar_starter_medal.png', title: 'Rising Star'},
        {img: 'avatar_starter_cat.png', title: 'Cat Pajamas'},
        {img: 'avatar_starter_fox.png', title: 'Fox Pajamas'},
        {img: 'avatar_starter_bear.png', title: 'Bear Pajamas'},
        {img: 'avatar_starter_bunny.png', title: 'Bunny Pajamas'},
      ]
    }
  ];

  allbirthcards: any = [
    {
      id: '21-3',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-the-world-the-empress',
      num: [21, 3],
      cards: ['major-23.png', 'major-05.png'],
      title: 'World, Empress'
    },
    {
      id: '20-2',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-judgement-the-high-priestess',
      num: [20, 2],
      cards: ['major-22.png', 'major-04.png'],
      title: 'Judgement, High Priestess'
    },
    {
      id: '19',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-the-sun-wheel-magician',
      num: [19],
      cards: ['major-21.png', 'major-03.png', 'major-12.png'],
      title: 'Sun, Wheel, Magician'
    },
    {
      id: '18-9',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-the-moon-the-hermit',
      num: [18, 9],
      cards: ['major-20.png', 'major-11.png'],
      title: 'Moon, Hermit'
    },
    {
      id: '17-8',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-the-star-strength',
      num: [17, 8],
      cards: ['major-19.png', 'major-10.png'],
      title: 'Star, Strength'
    },
    {
      id: '16-7',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-tower-chariot',
      num: [16, 7],
      cards: ['major-18.png', 'major-09.png'],
      title: 'Tower, Chariot'
    },
    {
      id: '15-6',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-devil-lovers',
      num: [15, 6],
      cards: ['major-17.png', 'major-08.png'],
      title: 'Devil, Lovers'
    },
    {
      id: '14-5',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-temperance-the-hierophant',
      num: [14, 5],
      cards: ['major-16.png', 'major-07.png'],
      title: 'Temperance, Hierophant'
    },
    {
      id: '13-4',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-death-the-emperor',
      num: [13, 4],
      cards: ['major-15.png', 'major-06.png'],
      title: 'Death, Emperor'
    },
    {
      id: '12-3',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-hanged-man-empress',
      num: [12, 3],
      cards: ['major-14.png', 'major-05.png'],
      title: 'Hanged Man, Empress'
    },
    {
      id: '11-2',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-justice-the-high-priestess',
      num: [11, 2],
      cards: ['major-13.png', 'major-04.png'],
      title: 'Justice, High Priestess'
    },
    {
      id: '10-1',
      url: 'https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-birth-card-combination-meanings-wheel-of-fortune-magician',
      num: [10, 1],
      cards: ['major-12.png', 'major-03.png'],
      title: 'Wheel, Magician'
    }
  ];

  getSubscription(name) {
    return this[name];
  }

  publishSubscription(name, data) {
    this[name].next(data);
  }

  async init() {
    // get uuid to passdown
    this.uuid = await Device.getId();
    this.uuid = this.uuid.identifier;

    // check & set token
    this.storage.ready().then(() => {
      this.storage.get('token').then(data => {
        if (data) {
          this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Token ' + data);
        } else {
          // this.router.navigateByUrl('/auth');
        }
      })
    }).then(() => {
      this.getUser();
    });
  }

  async showToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      duration: 5000,
      color: color
    });
    toast.present();
  }

  async clearData() {
    // if credits exist, they can't reset them.
    var defaultUser = JSON.parse(JSON.stringify(this.defaultUser));

    // avoid login and logout trick
    if (this.user && this.user.credits && this.user.credits < 33) {
      defaultUser.credits = this.user.credits;
    };

    // manually clear this data
    this.user = defaultUser;

    return this.storage.clear().then(data => {
      console.log('cleared user data...');
      this.setUser(defaultUser);
    });
  }

  async getUserData() {
    console.log('Getting user data to set to storage...');

    // Retrieve token from storage
    const token = await this.storage.get('token');

    if (!token) {
      this.showToast('Error retrieving user data. Please log out and log back in to update user data.', 'danger');
      return Promise.reject('No token found');
    }

    // Set Authorization header
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Token ' + token);

    // Fetch user data from API
    try {
      const response: any = await this.httpClient.get(this.url + 'user', this.httpOptions).toPromise();

      // Chain setData calls
      await this.setData('name', response['name'], false);
      await this.setData('level', response['level'], false);
      await this.setData('exp', response['exp'], false);
      await this.setData('email', response['email'], false);
      await this.setData('credits', response['credits'], false);
      await this.setData('user_data', response['user_data'], false);

      // Handle lessons
      const v2Lessons = response['lessons'].filter(
        (lesson: string) => this.lessonService.getLessonIds().includes(lesson) && lesson !== ''
      );

      if (v2Lessons.length === 0 && response['lessons'].length > 0) {
        console.log('Setting lessons in the database!');
        await this.setData('lessons', v2Lessons, true);
      } else {
        await this.setData('lessons', v2Lessons, false);
      }

      return Promise.resolve(); // Explicitly resolve to indicate completion

    } catch (error) {
      console.error('Error fetching user data:', error);
      this.showToast('Error retrieving user data. Please try again later.', 'danger');
      return Promise.reject(error);
    }
  }

  async getToken() {
    return await this.storage.get('token');
  }

  async getUserFromStorage() {
    return await this.storage.get('user');
  }

  async getUser() {
    // check if user exists in storage
    const userData = await this.getUserFromStorage().then(
      data => {
        if (data) {
          // temporary setup for astrology decks missing
          if (!data.astroDeck) {
            data.astroDeck = 'CADIS';
          };

          // temporary setup for astrology decks missing
          if (!data.dailyType) {
            data.dailyType = 'daily';
          };

          // temporary setup for rune decks missing
          if (!data.runeDeck) {
            data.runeDeck = 'rock';
          };

          // for old users, if credits does not exist, set default as 33
          // when variable is 0, also counts as false, getting reset to 33
          if (!data.credits && data.credits != 0) {
            data.credits = 33;
          };

          this.user = data;
          this.publishSubscription('userSubscription', data);

          return data;
        } else {
          console.log('user data not found, resetting...');
          // if credits alsready exist, keep them otherwise, reset
          var defaultUser = JSON.parse(JSON.stringify(this.defaultUser));
          if (this.user && this.user.credits && this.user.credits < 33) {
            defaultUser.credits = this.user.credits;
          };

          this.setUser(defaultUser);
          return this.defaultUser;
        }
      },
      error => console.error(error)
    )
    return userData;
  }

  async setUser(userData) {
    this.publishSubscription('userSubscription', userData);
    return await this.storage.set('user', userData);
  }

  async setToken(token) {
    return await this.storage.set('token', token);
  }

  async setData(key, value, db) {
    return this.storage.get('user').then((data) => {
      if (data) {
        data[key] = value;
        this.publishSubscription('userSubscription', data);
        return this.storage.set('user', data).then(
          () => {
            // avatar image isn't saved on server.
            if (key == 'title') {
              return this.setData('img', this.getUserPhotoFromTitle(value), false);
            }

            if (db) {
              // only upload to DB if variable 'db' is true
              // only values possible available in the DB on top level
              const possible = ['name', 'level', 'exp', 'email', 'lessons', 'credits'];
              if (possible.indexOf(key) > -1) {
                return this.httpClient.put(this.url + 'user', {[key]: value} , this.httpOptions).subscribe( response => {
                  console.log(`Set ${key}: ${value} in user DB!`);
                });
              }
            }
          },
          error => console.error('Error storing item', error)
        );
      } else {
        // when nothing in storage, data is null...
        return this.getUser().then(() => this.setData(key, value, true));
      }
    });
  }

  setUserData(key, value, db) {
    return this.storage.get('user').then((data) => {
      if (data) {
        var existingData = data.user_data;
        var newData = {[key]: value};
        var updatedData = Object.assign({}, existingData, newData);
        data.user_data = updatedData;
        this.publishSubscription('userSubscription', data);
        return this.storage.set('user', data).then(
          () => {
            console.log('set in storage')
            if (db) {
              console.log('attempting to set in DB');
              if (this.possibleUserData.indexOf(key) > -1) {
                var userData = {
                  user_data: {[key]: value}
                };
                return this.httpClient.put(this.url + 'user/merge-user-data', userData , this.httpOptions).subscribe(response => {
                  console.log(`Set ${key}: ${value} in user_data DB!`, response);
                });
              } else {
                console.log('couldnt set in DB', key, value);
              }
            }
          },
          error => console.error('Error storing item', error)
        );
      };
    });
  }

  setUserDataObj(value, db) {
    return this.storage.get('user').then((data) => {
      if (data) {
        var existingData = data.user_data;
        var updatedData = Object.assign({}, existingData, value);
        data.user_data = updatedData;
        this.publishSubscription('userSubscription', data);
        return this.storage.set('user', data).then(
          () => {
            if (db) {
              var cleanData = {};
              for (let item in value) {
                if (this.possibleUserData.indexOf(item) > -1) {
                  cleanData[item] = value[item];
                }
              };
              return this.httpClient.put(this.url + 'user/merge-user-data', {user_data: cleanData}, this.httpOptions).subscribe( response => {
                console.log(`Set user_data DB!`, {user_data: cleanData});
              });
            }
          },
          error => console.error('Error storing item', error)
        );
      };
    });
  }

  // for single piece of data
  getUserInfo(key: string, userData: boolean) {
    return this.storage.get('user').then((data) => {
      if (userData && data.user_data) {
        return data.user_data[key];
      } else {
        return data[key];
      }
    });
  }

  async setTag(tags: Array<string>) {
    if (this.user.email) {
      return this.httpClient.put(this.url + 'user/tags', {tags} , this.httpOptions).subscribe( response => {
        console.log('Updated user tags in Omnisend ', tags);
      });
    }
  }

  // set amount of credits using new API endpoint
  async updateCredits(method: "add" | "subtract", credits: number) {
    if (method == "subtract") {
      credits = credits * -1;
    };

    return await this.httpClient.put(this.url + 'user/credits', {amount: credits}, this.httpOptions).subscribe(response => {
      console.log('Updated credits in database ', response);
      if (response['credits']) {
        this.user.credits = response['credits'];
        return this.setUser(this.user).then((data) => {
          this.publishSubscription('userSubscription', this.user);
        });
      }
    });
  }

  getAvatars(id: string) {
    return this.avatarPacks.find((pack) => {
      return pack.id == id;
    });
  }

  getAllAvatars() {
    return this.avatarPacks;
  }

  getAvatarFromTitle(title: string) {
    var allAvatars = [];

    this.avatarPacks.forEach((pack) => {
      allAvatars.push(pack.avatars);
    });

    return allAvatars.flat().filter((avatar) => {
      return avatar.title == title;
    })[0];
  }

  getAvatarObjects(id: string) {
    var allAvatars = this.getAvatars(id);
    return allAvatars.avatars.map((obj) => {
      // value is the image
      // text is the name of the avatar
        return {
          value: obj.img,
          text: obj.title
        };
    });
  }

  getUserPhoto(level) {
    return 'avatar_' + (level - 1) + '.png';
  }

  getUserPhotoFromTitle(title) {
    if (this.titles.indexOf(title) > -1) {
      // standard titles
      const index = this.titles.indexOf(title);
      return 'avatar_' + index + '.png';
    } else {
      // premium avatars
      console.log(this.getAvatarFromTitle(title));
      return this.getAvatarFromTitle(title).img;
    }
  }

  getUserTitle(level) {
    return this.titles[level];
  }

  getAvailableTitles() {
    const index = this.user.level;
    console.log('available titles are: ' + this.titles.slice(0, index + 1));
    return this.titles.slice(0, index + 1);
  }

  getNextLevel(exp) {
    for (let expLevel of this.expLevels) {
      if (expLevel > exp) {
        return this.expLevels.indexOf(expLevel);
      }
    }
  }

  getExpByLevel(level) {
    return this.expLevels[level];
  }

  parseBirthDate(birthdate: string) {
    var split = birthdate.split("/");
    const parsedDate = {
      month: parseInt(split[0]),
      date: parseInt(split[1]),
      year: parseInt(split[2])
    };
    return parsedDate;
  }

  getBirthCards(m, d, y) {
    var digits = [m, d, parseInt(y.toString().slice(0,2)), parseInt(y.toString().slice(2,4))];
    var sum1 = digits.reduce((a, b) => a + b, 0);
    var sum2 = parseInt(sum1.toString().slice(0,1)) + parseInt(sum1.toString().slice(1,2));

    var card1;
    var card2;

    if (sum1.toString().length == 2) {
      if (sum2 < 10) {
        card1 = sum2;
        card2 = card1 + 9;
      } else {
        card1 = parseInt(sum2.toString().slice(0,1)) + parseInt(sum2.toString().slice(1,2));
        card2 = card1 + 9;
      }
    } else if (sum1.toString().length == 3) {
      card1 = parseInt(sum1.toString().slice(0,2)) + parseInt(sum1.toString().slice(2,3));
      card2 = parseInt(card1.toString().slice(0,1)) + parseInt(card1.toString().slice(1,2));
    } else if (sum1.toString().length == 1) {
      card1 = sum1;
      card2 = card1 + 9;
    };

    if (card1 == 19) {
      return this.allbirthcards[2];
    } else if (card1 <= 21) {
      var cards = this.allbirthcards.filter((set) => {
        return set.num.indexOf(card1) > -1;
      });
      if (card2) {
        var cards = cards.filter((set) => {
          return set.num.indexOf(card2) > -1;
        });
      }
      return cards[0];
    } else {
      return this.allbirthcards.filter((set) => {
        return set.num.indexOf(card2) > -1;
      })[0];
    };
  }
}
