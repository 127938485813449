import { Injectable } from '@angular/core';
import { Platform } from "@ionic/angular";
import { environment } from './../../environments/environment';
import {
    Purchases,
    PurchasesOfferings,
		LOG_LEVEL,
} from '@revenuecat/purchases-capacitor';
import { Purchases as PurchasesWeb } from '@revenuecat/purchases-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AlertController, ToastController, LoadingController, ModalController } from '@ionic/angular';
import { User } from '../user';
import { UserService } from '../services/user.service';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../services/analytics.service';
import { DownloadService } from '../services/download.service';
import { ProductService } from '../services/product.service';
import { Storage } from '@ionic/storage';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})

export class PurchaseService {
	constructor(
		private userService: UserService,
    private toastController: ToastController,
		private loadingController: LoadingController,
    private alertController: AlertController,
		private modalController: ModalController,
		private router: Router,
		private analyticsService: AnalyticsService,
		private downloadService: DownloadService,
    private productService: ProductService,
		private translate: TranslateService,
		private storage: Storage,
		private platform: Platform,
  ) {
    this.checkMigration();
		this.userService.getUser().then(data => {
			this.user = data;
			// set blank permissions if none exist
			if (!data.permissions || !data.permissions.avatars || !data.permissions.features || !data.permissions.decks) {
				var blankPermissions = {
					avatars: [],
					features: [],
					decks: [],
					journals: []
				};
				this.userService.setData('permissions', blankPermissions, false);
			};

			if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android') {
				this.init();
			} else if (Capacitor.getPlatform() == 'web' && this.user.email) {
        this.initWeb(this.user.email);
			} else {
        console.log('On web and not signed up, do not init revenuecat until login.');
      };
    });

		this.userUpdates = this.userService.getSubscription('userSubscription').subscribe((data) => {
			this.user = data;
		});

		this.checkDealEligibility();
	}

  offerings: BehaviorSubject<any> = new BehaviorSubject([]);
	rcPermissions = new BehaviorSubject({ avatars: [], features: [], decks: [], journals: [] });
	userUpdates: Subscription;
  user: any;
	allPurchases: any;
	downloadFlag: boolean = false;
	expiration: any;
  managementURL: any;
  web: boolean = Capacitor.getPlatform() == 'web';

  checkMigration() {
    this.storage.get('RC_MIGRATION').then(data => {
      if (!data) {
        console.log('background migration to revenuecat...');
        Purchases.syncPurchases();
        this.storage.set('RC_MIGRATION', true);
      }
    })
  }

	checkDealEligibility() {
    return this.storage.get('deal_expiration').then(data => {
      if (data) {
        return this.expiration = data;
      }
    })
  }

	checkDealExpired() {
		this.checkDealEligibility();
		if (this.expiration) {
			var now = new Date();
			var distance = new Date(this.expiration).valueOf() - now.valueOf();
			if (distance < 0) {
				return true;
			} else {
				return false;
			}
		} else {
			// don't show deal if there is no expiration
			return true;
		}
	}

  setCountdown() {
    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var timer;
		var countdown = {
			dd: 0,
			hh: 0,
			mm: 0,
			ss: 0
		};

    var now = new Date();
    var distance = new Date(this.expiration).valueOf() - now.valueOf();
    if (distance < 0) {
      return false;
    }

    countdown.dd = Math.floor(distance / _day);
    countdown.hh = Math.floor((distance % _day) / _hour);
    countdown.mm = Math.floor((distance % _hour) / _minute);
    countdown.ss = Math.floor((distance % _minute) / _second);

		return countdown;
  }

	async checkDownloadedDecks(decks: Array<string>) {
		if (decks?.length > 0) {
			if (Capacitor.getPlatform() == 'ios') {
				// get existing files and their URL
				// ios sometimes changes file locations...
				// needs to see if directory exists first
				var files: any = await this.downloadService.fileInfo(this.downloadService.downloadPathWrite);
			}

			var toDownload = [];
			var toRelink = [];
			await this.storage.ready();
			for (let d of decks) {
				var deckImages = await this.storage.get('deck_' + d);
				// if the url is already in storage
	      // case when they download new app version
				if (deckImages) {
					if (Capacitor.getPlatform() == 'ios') {
						var image = deckImages['back-01.png'].replace('back-01.png', '');
						var deckFiles = files.files.find((f) => { return f.name == d });
						var correct = deckFiles.uri.split('/Application/')[1];
						if (image.indexOf(correct) === -1) {
							console.log(`deck image not found, existing images are at ${correct}, but saved url is at ${image.split('/Application/')[1]}`);
							toRelink.push(d);
							// console.log(toDownload);
							// this.downloadService.downloadFiles(d);
						} else {
							console.log(`deck images found, existing images are at ${correct}, and saved url is at ${image.split('/Application/')[1]}`);
						}
					}
				// if urls do not exist
				// like after uninstalling and reinstalling
				} else {
					toDownload.push(d);
				};
			}
      console.log('relinking:', toRelink);
      console.log('reDownload:', toDownload);
			if (await toDownload.length > 0) {
				toDownload.forEach((d) => {
					this.downloadService.downloadFiles(d);
				});
			};

			if (await toRelink.length > 0) {
				await this.downloadService.quietRelinkiOS(toRelink);
				// window.location.reload();
			}
		}
	}

  getManagementURL() {
    return this.managementURL;
  }

	async presentDownloadAlert(ids: Array<string>) {
		const alert = await this.alertController.create({
			header: this.translate.instant('tab_shop.alert_download.title'),
			message: this.translate.instant('tab_shop.alert_download.message'),
			mode: 'md',
			cssClass: 'confirm',
			buttons: [
				{
					text: this.translate.instant('tab_shop.alert_download.button'),
					role: 'confirm',
					handler: () => {
						ids.forEach((d) => {
							this.downloadService.downloadFiles(d);
						});
						this.user.tarotDeck = 'SSTRWS';
						this.userService.setUser(this.user).then((data) => {
							this.userService.publishSubscription('userSubscription', this.user);
						});
					}
				},
				{
					text: this.translate.instant('_common.cancel'),
					role: 'cancel',
					handler: () => {
						var message = this.translate.instant('tab_shop.alert_download.toast');
						this.showToast(message, 'primary');
					}
				}
			],
		});
		await alert.present();
	}

	async showToast(message: string, color: string) {
		const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      duration: 5000,
			color: color
    });
    toast.present();
	}

  getDecksFromPermissions(permissions: any) {
    // permissions = customerInfo.entitlements.active;
    var decks = Object.keys(permissions).filter((perm) => {
      if (perm.includes('deck')) {
        return perm;
      };
    }).map(d => d.replace('deck_', '').toUpperCase());
    return decks;
  }

  async initWeb(id: string) {
    console.log(`initializing RevenueCat with user ${id}`);
    try {
      PurchasesWeb.configure(environment.RCWeb, id);
      var customerInfo = await PurchasesWeb.getSharedInstance().getCustomerInfo();
      this.handleExistingPermissions(customerInfo.entitlements.active);

      // Get all offerings (products)
      const offerings = await PurchasesWeb.getSharedInstance().getOfferings();
      this.offerings.next(offerings.all);
      console.log(this.offerings);
    } catch(e) {
			console.log('init error: ', e);
		};
  }

  async init() {
		console.log(`initializing RevenueCat with user ${this.user.email}`);
		try {
			await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
			if (Capacitor.getPlatform() == 'ios') {
				await Purchases.configure({
					apiKey: environment.RCApple
				});
			};

			if (Capacitor.getPlatform() == 'android') {
				await Purchases.configure({
					apiKey: environment.RCAndroid
				});
			};

			if (this.user.email) {
				await Purchases.logIn({ appUserID: this.user.email });
			};

			// connect user, then after every action, automatically set permissions.
			await Purchases.addCustomerInfoUpdateListener((customerInfo) => {
				this.handleExistingPermissions(customerInfo.entitlements.active);
        this.managementURL = this.managementURL;
			});

      // only check non-rune decks once on init
      var nonRunes = this.productService.removeRuneDecks(this.user.permissions.decks);
      this.checkDownloadedDecks(nonRunes);

			// Get all offerings (products)
			const offerings = await Purchases.getOfferings();
			this.offerings.next(offerings.all);
		} catch(e) {
			console.log('init error: ', e);
		}
	}

	async showLoading() {
    const loading = await this.loadingController.create({
      message: this.translate.instant('purchases.wait'),
      spinner: 'crescent'
    });
    loading.present();
  }

	async dismissLoading() {
		if (await this.loadingController.getTop() !== undefined) {
      await this.loadingController.dismiss();
    }
  }

	async dismissModal() {
		if (await this.modalController.getTop() !== undefined) {
			await this.modalController.dismiss();
		}
	}

  async purchaseWeb(aPackage: any, runeDeck?: string) {
    console.log('purchasing package...', aPackage);
    try {
      this.showLoading();
      const transaction: any = await PurchasesWeb.getSharedInstance().purchase({
        rcPackage: aPackage
      });
      if (transaction) {
        Promise.resolve()
        .then(() => {
          var entitlements = transaction.customerInfo.entitlements?.active;
          return this.handleExistingPermissions(entitlements);
        })
        .then(() => {
          return this.handleSuccessfulTransactionResult(transaction, aPackage);
        })
        .then(() => {
          if (runeDeck) {
            this.addRunePermissions(runeDeck);
          };
          return;
        })
        .then(() => {
          return this.dismissLoading();
        });
      }
    } catch (e) {
      console.log(e);
      this.dismissLoading();
      const alert = await this.alertController.create({
        header: this.translate.instant('purchases.failed_title'),
				message: this.translate.instant('purchases.failed_message'),
				mode: 'md',
				cssClass: 'confirm',
        buttons: ['OK'],
      });
      await alert.present();
    }
  }

  async purchase(aPackage: any, runeDeck?: string) {
    console.log('purchasing package...', aPackage);
    try {
			this.showLoading();
      const transaction = await Purchases.purchasePackage({ aPackage: aPackage });
      if (transaction) {
				Promise.resolve()
				.then(() => {
          // console.log('handle successful transaction...');
          return this.handleSuccessfulTransactionResult(transaction, aPackage);
        })
        .then(() => {
          // console.log('handle rune deck permissions...');
          if (runeDeck) {
            this.addRunePermissions(runeDeck);
          };
          return;
        })
				.then(() => {
          // console.log('dismiss loading screen...');
					return this.dismissLoading();
        })
      }
    } catch (e) {
			this.dismissLoading();
      const alert = await this.alertController.create({
        header: this.translate.instant('purchases.failed_title'),
				message: this.translate.instant('purchases.failed_message'),
				mode: 'md',
				cssClass: 'confirm',
        buttons: ['OK'],
      });
      await alert.present();
    }
  }

  addRunePermissions(userDeck: string) {
    var user = this.rcPermissions.getValue();
    var runePermissions = this.user?.user_data?.runeDecks;
    if (!runePermissions) {
      runePermissions = [];
    };
    if (user.decks.indexOf(userDeck) == -1) {
      user.decks.push(userDeck);
      this.rcPermissions.next(user);
      this.userService.setData('permissions', this.rcPermissions.value, false);
    };
    if (runePermissions.indexOf(userDeck) == -1) {
      runePermissions.push(userDeck);
      // otherwise this will conflict with handleExistingPermissions which autofires...super annoying!
      setTimeout(() => {
        return this.userService.setUserData('runeDecks', runePermissions, true);
      }, 3000);
    };
  }

	async showPurchasedAlert(aPackage: any) {
		const purchasedAlert = await this.alertController.create({
			header: 'You already own this item.',
			message: this.translate.instant('purchases.failed_message'),
			mode: 'md',
			cssClass: 'confirm',
			buttons: ['OK'],
		});
		await purchasedAlert.present();
	}

  handleExistingPermissions(permissions: any) {
		const user = this.rcPermissions.getValue();
    // add runedecks
    var runeDecks = this.user?.user_data?.runeDecks;
    if (runeDecks) {
      runeDecks.forEach(d => user.decks.push(d));
    };
    console.log('does user own runedecks?', permissions, runeDecks, user.decks);
    // add everything else
		for (const permId of Object.keys(permissions)) {
			var perm = permissions[permId];
			if (perm.isActive) {
				if (permId) {
					// avatar permissions
					if (permId.indexOf('avatar') > -1) {
						if (user.avatars.indexOf(permId) === -1) {
							user.avatars.push(permId);
						}
					// decks permissions
					} else if (permId.indexOf('deck') > -1) {
						// publisher decks also have _ to denote publisher. It's included in the deckId. this is the case except for deck_rune
						var deckId = permId.replace('deck_', '').toUpperCase();
						if (user.decks.indexOf(deckId) === -1) {
							user.decks.push(deckId);
						}
					// journal pages permissions
					} else if (permId.indexOf('journal') > -1) {
						if (user.journals.indexOf(permId) === -1) {
							user.journals.push(permId);
						}
					} else {
						if (user.features.indexOf(permId) === -1) {
							user.features.push(permId);
						}
					}
				}
			}
		}
		this.rcPermissions.next(user);
		this.userService.setData('permissions', this.rcPermissions.value, false);
		console.log('handled existing permissions: ', this.rcPermissions.value);
  }

  handleSuccessfulTransactionResult(
    transaction: any,
    sku: any
  ) {
    const isWeb = this.web;
    const productIdentifier = isWeb ? sku.rcBillingProduct.identifier : sku.product.identifier;
    const productType = isWeb ? sku.rcBillingProduct.presentedOfferingIdentifier : sku.offeringIdentifier;
    const price = isWeb ? sku.rcBillingProduct.currentPrice.amount : sku.product.price;
    const currency = isWeb ? sku.rcBillingProduct.currentPrice.currency : sku.product.currencyCode;

    console.log(productType);
    if (transaction && productType === 'consumables') {
      // Credits type purchases
      console.log('is credit type purchase');
      const credits = parseInt(productIdentifier.replace('c', ''));
      this.userService.updateCredits('add', credits);
      this.showToast(this.translate.instant('purchases.success'), 'success');

    } else if (transaction && productType === 'avatars') {
      // Avatar type purchases
      console.log('is avatar type purchase');
      this.showToast('Purchase Completed. Thank you!', 'success');
    } else if (transaction && productType === 'tarot') {
      // Deck type purchases
      console.log('is deck type purchase', productIdentifier);
      // Download File only on mobile
      if (!isWeb) {
        const deckId = productIdentifier.replace('deck_', '').toUpperCase();
        this.downloadService.downloadFiles(deckId);
      }
    } else if (transaction && productType === 'journals') {
      // Journal purchases
      console.log('is journal type purchase');
      this.showToast('Purchase Completed. Thank you!', 'success');
    } else if (transaction && productType === 'premium') {
      // Premium purchases
      console.log('is premium type purchase');
      this.showToast('You have unlocked premium. Thank you!', 'success');
    }

		// analytics stuff -- note: apparently the event "in_app_purchase" is auto-detected by android, but not for iOS. You need to ONLY get iOS values. Prior to version 1.61, "purchase" was a reserved keyword for ecommerce data, meaning parameters like sku, value and currency were not tracked at all. For total revenue data before 1.61, you should look at ONLY purchase event, ignore total revenue in analytics. Post 1.61, look at purchase events, but now using the correct "ecommerce" data.
		this.analyticsService.logEvent('purchase', {
			item_id: sku.skuId,
			value: price,
			currency: currency
		});
  }

	setOmnisendTags(type: 'credits' | 'deck' | 'premium' | 'journals', data: string) {
		if (type == 'credits') {
			var tag = 'AI-readings_' + data;
			this.userService.setTag([tag]);
		}
		if (type == 'deck') {
			var tag = 'Digideck_' + data;
			this.userService.setTag([tag]);
		}
		if (type == 'premium') {
			var tag = 'Premium_' + data;
			this.userService.setTag([tag]);
		}
		if (type == 'journals') {
			var tag = data;
			this.userService.setTag([tag]);
		}
	}

	updateUserService(credits) {
		this.userService.updateCredits('add', credits);
	}

  async checkTrial(ids) {
    return Purchases.checkTrialOrIntroductoryPriceEligibility({
      productIdentifiers: ids
    })
  }

	getOfferings() {
		return this.offerings.asObservable();
	}

  async getSkuFromProductId(productId: string) {
    // android and iOS have different cases...
    if (Capacitor.getPlatform() == 'ios') {
      var skuId = 'deck_' + productId;
    };
    if (Capacitor.getPlatform() == 'android') {
      var skuId = 'deck_' + productId.toLowerCase();
    };
    return Purchases.getProducts({productIdentifiers: [skuId]});
  }

	getPermissions() {
		return this.rcPermissions.value;
	}

	async checkToken() {
		if (this.router.url != 'auth') {
			this.userService.getToken().then( data => {
				if (!data) {
					this.router.navigateByUrl('/auth');
					this.dismissModal();
				} else {
					console.log('token exists: ', data);
				}
			});
		}
  }

	resetDeck() {
		this.user.tarotDeck = 'SSTRWS';
		this.userService.setUser(this.user).then((data) => {
			this.userService.publishSubscription('userSubscription', this.user);
		});
	}

	async restore() {
		this.downloadFlag = false;
    var android = Capacitor.getPlatform() == 'android';
    var ios = Capacitor.getPlatform() == 'ios';
		if (android || ios) {
      try {
        if (this.user.email) {
          await Purchases.logIn({ appUserID: this.user.email });
        };

        this.showLoading();
        const permissions = await Purchases.restorePurchases();

        // get deckIds from permissions
        var decks = permissions.customerInfo.entitlements.active;
        var deckIds = Object.keys(decks).filter(id => id.includes('deck'));
        deckIds = deckIds.map(id => id.replace('deck_', ''));
        this.dismissLoading();

        // chain promises for downloadservice, sequentially
        var result = Promise.resolve();
        deckIds.forEach(deckId => {
          result = result.then(() => this.downloadService.downloadFiles(deckId));
        });
        return result.then(() => {
          this.showToast('👍 Restored all valid permissions. For credit related issues, please email us at faculty@labyrinthos.co!', 'success');
        })
        .then(() => {
          // needs to reset to default deck because even with restore purchases, they need to change default deck again.
          this.resetDeck();
        })
      } catch (e) {
        this.dismissLoading();
        this.showToast('No receipts found for this user. Are you signed into the correct account? For help, please email us at faculty@labyrinthos.co!', 'danger');
      }
		} else {
			var result = Promise.resolve();
			this.user.permissions.decks.forEach((deck) => {
				result = this.downloadService.downloadFiles(deck); // for testing purposes
			});
			return result
			.then(() => {
				this.resetDeck();
			});
		}
	}
}
