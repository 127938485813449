import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TarotService } from '../../services/tarot.service';
import { LenormandService } from '../../services/lenormand.service';
import { AstroService } from '../../services/astro.service';
import { RuneService } from '../../services/rune.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.page.html',
  styleUrls: ['./select-modal.page.scss'],
})
export class SelectModalPage implements OnInit {
  constructor(
    private modalController: ModalController,
    private lenormandService: LenormandService,
    private tarotService: TarotService,
    private astroService: AstroService,
    private runeService: RuneService,
    private translate: TranslateService
  ) {}

  @Input() userDeck: any;
  @Input() cardsInSpread: any;
  @Input() spreadType: string;
  cardsInDeck = null;
  searchText = null;
  translationPath: string;
  deckInfo: any;

  async dismiss() {
    const modal = await this.modalController.getTop();
    modal.dismiss();
  }

  setFilteredItems() {
    if (this.spreadType == 'lenormand') {
      this.cardsInDeck = this.lenormandService.filterCards(this.searchText);
    } else {
      this.cardsInDeck = this[this.spreadType + 'Service'].filterCards('search', this.searchText);
    }
  }

  getCardsInDeck(cards) {
    return cards.map((val, i, arr) => {
      console.log(val)
      return {
        image: val.image,
        title: this.translate.instant(this.translationPath + '.' + val.image + '.title'),
        reversed: val.reversal
      }
    })
  }

  async selectCard(cardId, reversed) {
    const modal = await this.modalController.getTop();
    modal.dismiss({
      card_id: cardId,
      reversed: reversed
    });
  }

  ngOnInit() {
    // console.log('modal open', this.cardsInSpread);
    // get translations

    if (this.spreadType == 'tarot') {
      this.translationPath = this.tarotService.getDeckTranslateVariable(this.userDeck);
      var cards = this.tarotService.getTarot();
      this.deckInfo = this[this.spreadType + 'Service'].decks[this.userDeck];
    } else {
      this.translationPath = this.spreadType;
      this.deckInfo = this[this.spreadType + 'Service'][this.spreadType];
      if (this.spreadType == 'lenormand') {
        var cards = this[this.spreadType + 'Service'].getLenormand();
      } else if (this.spreadType == 'rune') {
        var cards = this[this.spreadType + 'Service'].getRunes();
      } else {
        var cards = this[this.spreadType + 'Service'].getAstro();
      }
    }
    this.cardsInDeck = cards;
    console.log(this.spreadType, this.cardsInDeck);
  }

}
