import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Device } from '@capacitor/device';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsEnabled = true;

  constructor(
    private router: Router,
    private clevertap: CleverTap
  ) {
    this.initFb();
    this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {
      this.setScreenName(e.url)
    });
  }

  async initFb() {
    var platform = await Device.getInfo();
    // only run firebase init if on web platform. Not iOS or android.
    if (platform && platform.platform == "web") {
      console.log('FirebaseAnalytics should be initializing...');
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }

  initCleverTap() {
    this.clevertap.notifyDeviceReady();
    document.addEventListener('deviceready', (e: any) => {
      console.log('hello! clevertap is loaded');
    });

    document.addEventListener('onCleverTapInAppNotificationDismissed', (e: any) => {
      console.log('onCleverTapInAppNotificationDismissed');
      console.log(JSON.stringify(e.extras));
      console.log(JSON.stringify(e.actionExtras));
    });

    document.addEventListener('onDeepLink', (e: any) => {
      console.log('onDeepLink');
      console.log(e.deeplink);
    });

    document.addEventListener('onPushNotification', (e: any) => {
      console.log('onPushNotification');
      console.log(JSON.stringify(e.notification));
    });

    document.addEventListener('onCleverTapPushNotificationTappedWithCustomExtras', (e: any) => {
      console.log('onCleverTapPushNotificationTappedWithCustomExtras');
      console.log(JSON.stringify(e.notification));
    });

    this.clevertap.setDebugLevel(3);
    this.clevertap.enablePersonalization();
  }

  async setUser() {
    // sets device id to tracking channels
    var id = await Device.getId();
    FirebaseAnalytics.setUserId({
      userId: id.identifier,
    });
  }

  async setClevertapUser(user: any) {
    var id = await Device.getId();
    var bdayObjSplit = user.user_data?.birthdate?.split('/');
    var bdayObj;
    if (bdayObjSplit) {
      bdayObj = new Date(bdayObjSplit[2], bdayObjSplit[0] - 1, bdayObjSplit[1]);
    };
    
    var clevertapProps = {
      'Name': user.name,
      'Identity': user.email,
      'Email': user.email,
      'DOB': bdayObj,
      'Gender': user.user_data?.pronouns === "she" ? "F" : user.user_data?.pronouns === "he" ? "M" : undefined,
      'tarotDeck': user.tarotDeck,
      'lenormandDeck': user.lenormandDeck,
      'astroDeck': user.astroDeck,
      'runeDeck': user.runeDeck,
      'level': user.level,
      'title': user.title,
      'exp': user.exp,
      'lessons': user.lessons,
      'credits': user.credits,
      'ownedAvatars': user.permissions?.avatars,
      'ownedDecks': user.permissions?.decks,
      'premium': user.permissions?.features?.includes('premium'),
      'autoSave': user.autoSave,
      'pronouns': user.user_data?.pronouns,
      'birthday': user.user_data?.birthdate,
      'relationship': user.user_data?.relationship,
      'favorited': user.user_data?.favoriteSpreads,
      'MSG-push': true,
    };

    this.clevertap.onUserLogin(clevertapProps);
    // console.log('logging in clevertap user', clevertapProps);
  }

  logEvent(name: string, params?: any) {
    // params are always a key value pair like so:
    // {
    //   method: "email"
    // }
    FirebaseAnalytics.logEvent({
      name: name,
      params: params
    });
    this.clevertap.recordEventWithNameAndProps(name, params)

  }

  setProperty(property) {
    // keep it a key value pair like so:
    // {
    //   name: "framework",
    //   value: "angular",
    // }
    FirebaseAnalytics.setUserProperty(property);
    this.clevertap.profileSet(property);
  }

  setScreenName(screenName) {
    FirebaseAnalytics.setScreenName({
      screenName
    });
    this.clevertap.recordEventWithNameAndProps("change_screen", {screen: screenName});
  }

  checkPushPermissions() {
    console.log('checking for push permissions');
    this.clevertap.registerPush();
    this.clevertap.promptForPushPermission(true);
    this.clevertap.isPushPermissionGranted().then(data => {
      console.log('push permissions: ' + data);
      this.clevertap.createNotificationChannel("labytarot", "Labyrinthos Tarot", "Labyrinthos Tarot Push Notifications", 3, true);
    });
  }
}
