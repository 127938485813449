import { Injectable } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { TarotService } from './tarot.service';
import { LenormandService } from './lenormand.service';
import { AstroService } from './astro.service';
import { RuneService } from './rune.service';
import { AnalyticsService } from './analytics.service';
import { CardModalPage } from '../modals/card-modal/card-modal.page';
import { InterpretPage } from '../modals/interpret/interpret.page';
import { SelectModalPage } from '../modals/select-modal/select-modal.page';
import { DefaultSettingsPage } from '../modals/default-settings/default-settings.page';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ProductService } from './product.service';
import { Spread } from '../spread';
import { User } from '../user';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ReadingService {
  constructor(
    private modalController: ModalController,
    private tarotService: TarotService,
    private lenormandService: LenormandService,
    private astroService: AstroService,
    private runeService: RuneService,
    private route: ActivatedRoute,
    private router: Router,
    private platform: Platform,
    private analyticsService: AnalyticsService,
    private productService: ProductService,
    private toastController: ToastController,
    private translate: TranslateService
  ) {
    // setup stuff for layout part
    this.platform.ready().then((readySource) => {
      this.device.width = this.platform.width() * 0.8;
      this.device.height = this.platform.height() * 0.6;
    });
  }

  settingsUpdates = new Subject<any>();
  spreadData = new Subject<any>();
  device:any = {
    width: null,
    height: null
  };

  getSubscription(name) {
    return this[name];
  }

  publishSubscription(name, data) {
    this[name].next(data);
  }

  async presentHelp(type: 'digital' | 'physical') {
    var message = this.translate.instant('get_reading.info_' + type);
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'secondary',
      cssClass: 'animated bounceInUp',
      buttons: [
        {
          text: 'OK!',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  convertFreeform(cards: Array<any>) {
    for (let card of cards) {
      // turn x and y properties to 'calc' and percent formats
      // make sure these are all numbers...
      // sometimes this puts all cards into a corner for auto-save readings.
      // it's saving before xPercent and yPercent are calculated
      // console.info(`device: `, this.device, `position: `, card.position);

      if (this.device.width <= 992) {
        var xPercent = Math.round((card.position.x / this.device.width) * 100);
        var yPercent = Math.round((card.position.y / this.device.height) * 100);
      } else {
        var xPercent = Math.round((card.position.x / 600) * 100);
        var yPercent = Math.round((card.position.y / this.device.height) * 100);
      }

      // console.info(card.position.x, this.device.width, xPercent);
      // console.info(card.position.y, this.device.height, yPercent);

      card.position.x = 'calc(' + xPercent + '% - 9vw)';
      card.position.y = yPercent + '%';
    }
    return cards;
  }

  async dismiss(id: string, data?: any) {
    if (data) {
      let modal = await this.modalController.dismiss({data}, '', id);
    } else {
      let modal = await this.modalController.dismiss({}, '', id);
    }
  }

  getExcludedCards(spread: Spread) {
    var pastCards = []
    var pastCardsClean = [];

    // get all cards alrady drawn in spread
    for (let card of spread.cards) {
      pastCards.push(card.card_id)
      if (card.clarifier) {
        pastCards.push(card.clarifier.card_id)
      }
    }

    // remove null objects (unchosen cards)
    pastCardsClean = pastCards.filter((card) => {
      return card != null;
    });

    if (spread.type != 'lenormand' && spread.type != 'astro' && spread.type != 'rune') {
      spread.type = 'tarot'
    };

    // transform to their indexes
    var pastCardsIndexes = pastCardsClean.map(x => this[spread.type + 'Service'].getCardIndex(x));
    return pastCardsIndexes;
  }

  // part of the work for digital readings -- currently duplicated, removed availableCard section specific to readings.
  getRandomCard(index: number, spread: Spread, deck: string) {
    var pastCardsIndexes = this.getExcludedCards(spread);

    // analytics stuff
    this.analyticsService.logEvent('draw_card', {
      content_type: spread.type,
      spread_id: spread.id,
      deck_id: deck,
      content_category: 'digital'
    });

    // generate random card, excluding past chosen cards
    // if no cards are left, function won't do shit
    if (spread.type == 'astro') {
      var rangeName = this.astroService.getSubsetNamebyIndex(index);
      var subsetIndexes = this.astroService.getCardArraysforSubset(rangeName);
      return this.astroService.getRandomCardInRange(subsetIndexes, pastCardsIndexes);
    } else {
      return this[spread.type + 'Service'].getRandomCard(pastCardsIndexes);
    }
  }

  // open reading settings
  async openSettings(user: User, type: string, spreadId?: string) {
    var props:any = {
      user: user,
      spreadType: type
    };

    const modal = await this.modalController.create({
      component: DefaultSettingsPage,
      componentProps: props,
      id: 'settings'
    });

    // on close - restart UI - change to get this based on url
    modal.onDidDismiss().then((data) => {
      if (data?.data?.user) {
        let dataChange = {
          deck: data.data.user[type + 'Deck'],
          dailyType: data.data.user.dailyType,
          readingType: data.data.user.readingType,
          subset: data.data.user.subset,
          reversals: data.data.user.reversals
        }
        this.publishSubscription('settingsUpdates', dataChange);
        this.analyticsService.logEvent('change_settings', {
          content_category: 'deck',
          source: 'reading_settings'
        });
      }
    });
    return await modal.present();
  }

  // selecting a card when in a physical reading
  async openCardSelector(deck: string, spread: any, spreadType: string, i: number, clarifier?: boolean) {
    var props = {
        userDeck: deck,
        cardsInSpread: spread.cards[i],
        spreadType: spreadType
    }
    const modalSelect = await this.modalController.create({
      component: SelectModalPage,
      componentProps: props,
      id: 'selector'
    });

    modalSelect.onDidDismiss()
      .then((data) => {
        const card = data['data'];
        if (card) {
          spread.i = i;
          console.log(card, clarifier)

          if (clarifier) {
            spread.cards[i].clarifier.card_id = card.card_id;
            spread.cards[i].clarifier.reversed = card.reversed;
          } else {
            spread.cards[i].card_id = card.card_id;
            spread.cards[i].reversed = card.reversed;
          };

          this.publishSubscription('spreadData', spread);

          // analytics stuff
          this.analyticsService.logEvent('draw_card', {
            content_type: spreadType,
            spread_id: spread.id,
            deck_id: deck,
            content_category: 'physical'
          });
        }
    });

    await modalSelect.present();
  }

  // opening card with a reading
  // HAS REPEATS TO AVOID CIRCULAR DEPENDENCIES
  async openCard(spread: Spread, i: number, deck: string, permissions?: any, clarifier?: boolean) {
    console.log('opening card...', spread);
    var type = this.productService.getProductType(deck);
    var card = this[type + 'Service'].getCardByDeck(spread.cards[i].card_id, deck)[0];
    var spreadId = spread.id;

    if (spread.id.includes('_')) {
      spreadId = spread.id.split('_')[0];
    };

    if (clarifier && spread.cards[i].clarifier) {
      card = this[type + 'Service'].getCardByDeck(spread.cards[i].clarifier.card_id, deck)[0];
    };

    var props = {
        card: card,
        userDeck: deck,
        spread: spread,
        index: i,
        spreadType: type,
        permissions: permissions,
        spreadId: spreadId
    };

    const modal = await this.modalController.create({
      component: CardModalPage,
      componentProps: props,
      id: card.image
    });

    this.analyticsService.logEvent('view_card', {
      content_type: type,
      card_id: card.image,
      deck_id: deck,
      source: 'reading'
    });

    return await modal.present();
  }

  // opening card outside readings (lessons and reference)
  async openCardSimple(type: string, deck: string, cardId: string, permissions?: any) {
    var props: any = {
      card: this[type + 'Service'].getCardByDeck(cardId, deck)[0],
      userDeck: deck,
      spreadType: type,
      permissions: permissions
    }

    this.analyticsService.logEvent('view_card', {
      content_type: type,
      card_id: cardId,
      deck_id: deck,
      source: 'meanings'
    });

    const modal = await this.modalController.create({
      component: CardModalPage,
      componentProps: props,
      id: cardId
    });

    return await modal.present();
  }

  async interpret(spread: Spread, availableCard, section, i, deck) {
    // show only single card view if not all cards are drawn or only 1 card in section
    if (
      (availableCard && availableCard != spread.cards.length) ||
      (section && section.length < 2)
    ) {
      this.openCard(spread, i, deck);
    } else {
      var props = {
          userDeck: deck,
          cardsInSpread: spread.cards,
          index: i,
          section: section,
          id: spread.id,
          spread: spread
      }
      const interpret = await this.modalController.create({
        component: InterpretPage,
        componentProps: props,
        id: i
      });
      await interpret.present();
    }
  }
}
