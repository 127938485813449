import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen'
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { UserService } from './services/user.service';
import { LogService } from './services/log.service';
import { PurchaseService } from './services/purchase.service';
import { ConnectivityService } from './services/connectivity.service';
import { LanguageService } from './services/language.service';
import { ThemeService } from './services/theme.service';
import { AnalyticsService } from './services/analytics.service';
import { ToastController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { RateService } from './services/rate.service';
import { Storage } from '@ionic/storage';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private userService: UserService,
    private logService: LogService,
    private connectivityService: ConnectivityService,
    private purchaseService: PurchaseService,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private languageService: LanguageService,
    private toastController: ToastController,
    private themeService: ThemeService,
    private platform: Platform,
    private rateService: RateService,
    private storage: Storage,
    private analyticsService: AnalyticsService
  ) {}

  @ViewChild('premium') premium: any;
  auth: boolean = false;
  web: boolean = Capacitor.getPlatform() == 'web';

  async offlineToast() {
    const toast = await this.toastController.create({
      message: 'No internet connection. Some services will not work.',
      position: 'top',
      color: 'danger',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        }
      ]
    });
    await toast.present();
  }

  async onlineToast() {
    const toast = await this.toastController.create({
      message: 'Your internet is back! Hooray!',
      duration: 3000,
      position: 'top',
      color: 'success'
    });
    await toast.present();
  }

  async dismissToast() {
    const toast = await this.toastController.getTop();
    if (toast) {
      toast.dismiss();
    }
  }

  checkVisitNumber() {
    var premium = this.userService.user?.permissions?.features?.indexOf('premium') > -1;
    var journal = this.userService.user?.permissions?.journals?.length > 0;

    this.storage.ready().then(() => {
      this.storage.get('visit_number').then(data => {
        if (data) {
          this.storage.set('visit_number', data + 1);
          if (data == 3) {
            this.rateService.firstAlert();
          }
          // if they have premium or they own a journal, don't show them ads all the time.
          if ((data % 5 == 0) && (!premium && !journal)) {
            if (!this.web) {
              this.premium.openEligibleModal(0);
            } else if (this.web && this.auth) {
              this.premium.openEligibleModal(0);
            };
          }
        } else {
          this.storage.set('visit_number', 1);
        }
      });
    })
  }

  checkDeepLinks() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        var urls = ['labyrinthos.app', 'app.labyrinthos.co'];
        const slug = urls.reduce((result, url) => {
          if (result) return result;
          const match = event.url.includes(url) ? event.url.split(url).pop() : null;
          return match;
        }, null);
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2

        this.router.navigateByUrl(slug);
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  refreshDataFromServer() {
    // choose which ones to selectively update.
    if (this.userService?.user?.email) {
      Promise.resolve()
        .then(() => {
          return this.userService.getUserData();
        })
        .then(() => {
          return this.logService.getAPILogs('v2');
        })
        .then(() => {
          return this.logService.getAPILogs('v2-spreads');
        })
        .then(() => {
          return this.logService.getAPILogs('score');
        });
    }
  }

  async setupClevertap() {
    var user = await this.userService.getUser();
    this.analyticsService.initCleverTap();
    this.analyticsService.setClevertapUser(user);
    this.analyticsService.checkPushPermissions();
  }

  ngOnInit() {
    setTimeout(() => {
      this.checkVisitNumber();
      this.setupClevertap();
    }, 2000);

    SplashScreen.show();
    this.languageService.setInitialAppLanguage();
    this.themeService.setInitialTheme();
    this.checkToken();
    this.checkFirstVisit();
    SplashScreen.hide();
    this.checkDeepLinks();

    this.connectivityService.onlineOffline.subscribe(online => {
      // may need testing on testflight, not just livereload device
      if (online) {
        this.dismissToast();
        this.refreshDataFromServer();
      } else {
        this.offlineToast();
      }
    });

    this.platform.resume.subscribe(async () => {
      // refreshes data everytime the app is in foreground
      // recheck subscriptions on app resume - if the subscription expired.
      // don't need to do this anymore because revenuecat checks w/ listener.
      this.refreshDataFromServer();
    });

    LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
      if (notification.notification.extra.type == 'hindsight') {
        const params = {
          id: notification.notification.extra.spreadId
        };
        this.router.navigate(['view-reading'], {queryParams: params});
      }
    });
  }

  async checkFirstVisit() {
    var login = await this.userService.getToken();

    this.storage.ready().then(() => {
      this.storage.get('visit_number').then(data => {
        var visit_number = data;
        if (!login && visit_number < 1) {
          if (this.web) {
            this.router.navigateByUrl('/home');
          } else {
            this.router.navigateByUrl('/auth');
          }
        };
      })
    });
  }

  checkToken() {
    this.userService.getToken().then(data => {
      this.auth = data;
      SplashScreen.hide();
    });
  }
}
