<ion-card class="product-shop" *ngIf="(product && rcSku) && productType == 'vertical'">
  <app-deck *ngIf="category != 'rune'"[product]="product" [view]="['cards', 'box-flat']" [height]="'200px'" (click)="navigate()" class="noScale"></app-deck>
  <app-deck *ngIf="category == 'rune'" [product]="product" [view]="['single']" (click)="navigate()"></app-deck>
  <ion-chip *ngIf="rcSku && owned" class="owned">
    <ion-icon name="checkmark-circle-outline"></ion-icon>
    Owned
  </ion-chip>
  <ion-card-header>
    <ion-card-title>{{product.name}}</ion-card-title>
    <ion-card-subtitle mode="md" *ngIf="product.creator">
      <em>by</em> {{product.creator[0]}}
    </ion-card-subtitle>
    <ion-card-subtitle mode="md" *ngIf="category == 'rune'">
      <span *ngFor="let keyword of 'crystals.' + product.id + '.keywords' | translate; index as i">
        <span *ngIf="i<3">{{keyword}}<span *ngIf="i<2">, </span></span>
      </span>
    </ion-card-subtitle>
    <div class="buttons">
      <!-- regular purchase -->
      <!-- non-rune mobile product -->
      <ion-button size="small" (click)="purchase(rcSku)" *ngIf="!web && rcSku && !owned && category != 'rune'" class="xsmall" [disabled]="!onlineOffline">
        <span *ngIf="onlineOffline">{{ rcSku.product.price | number:'1.0-2' | currency: rcSku.product.currencyCode }}</span>
        <span *ngIf="!onlineOffline">Offline</span>
      </ion-button>
      <!-- non-rune web product -->
      <ion-button size="small" (click)="purchase(rcSku)" *ngIf="web && rcSku && !owned && category != 'rune'" class="xsmall" [disabled]="!onlineOffline">
        <span *ngIf="onlineOffline">
          {{ rcSku.rcBillingProduct.currentPrice.formattedPrice }}
          {{ rcSku.rcBillingProduct.currentPrice.currency }}
        </span>
        <span *ngIf="!onlineOffline">Offline</span>
      </ion-button>

      <!-- rune purchase is different -->
      <!-- rune mobile product -->
      <ion-button size="small" (click)="purchase(rcSku, product.id)" *ngIf="!web && rcSku && !owned && category == 'rune'" class="xsmall" [disabled]="!onlineOffline">
        <span *ngIf="onlineOffline">{{ rcSku.product.price | number:'1.0-2' | currency: rcSku.product.currencyCode }}</span>
        <span *ngIf="!onlineOffline">Offline</span>
      </ion-button>
      <!-- rune web product -->
      <ion-button size="small" (click)="purchase(rcSku, product.id)" *ngIf="web && rcSku && !owned && category == 'rune'" class="xsmall" [disabled]="!onlineOffline">
        <span *ngIf="onlineOffline">
          {{ rcSku.rcBillingProduct.currentPrice.formattedPrice }}
          {{ rcSku.rcBillingProduct.currentPrice.currency }}
        </span>
        <span *ngIf="!onlineOffline">Offline</span>
      </ion-button>

      <ion-button size="small" (click)="openBrowser(product.url)" *ngIf="item && owned" class="xsmall">
        Buy Physical
      </ion-button>
      <ion-button fill="outline" size="small" (click)="navigate(product.url)" class="xsmall">
        More Info
      </ion-button>
    </div>
  </ion-card-header>
</ion-card>

<div class="product-featured" *ngIf="product && productType == 'featured' && rcSku">
  <app-deck *ngIf="rcSku && category != 'rune'" [product]="product" [view]="['cards', 'box-flat']" [height]="'250px'"></app-deck>
  <app-deck *ngIf="rcSku && category == 'rune'" [product]="product" [view]="['scatter']" [height]="'250px'"></app-deck>
  <div *ngIf="rcSku" class="blurb ion-margin-start ion-margin-end ion-padding">
    <ion-grid fixed>
      <p class="capitals">Featured Deck</p>
      <h4>{{ product.name }}</h4>
      <p class="author" *ngIf="product.creator">
        <span>by</span> {{product.creator[0]}}
      </p>
      <p class="author" *ngIf="category == 'rune'">
        <span *ngFor="let keyword of 'crystals.' + product.id + '.keywords' | translate">{{keyword}}</span>
      </p>
      <div class="buttons">
        <!-- regular purchase -->
        <!-- mobile product -->
        <ion-button size="small" (click)="purchase(rcSku)" *ngIf="rcSku && !owned && category != 'rune' && !web" class="xsmall" fill="outline" [disabled]="!onlineOffline">
          <span *ngIf="onlineOffline">{{ rcSku.product.price | number:'1.0-2' | currency: rcSku.product.currencyCode }}</span>
          <span *ngIf="!onlineOffline">Offline</span>
        </ion-button>
        <!-- web product  -->
        <ion-button size="small" (click)="purchase(rcSku)" *ngIf="rcSku && !owned && category != 'rune' && web" class="xsmall" fill="outline" [disabled]="!onlineOffline">
          <span *ngIf="onlineOffline">
            {{ rcSku.rcBillingProduct.currentPrice.formattedPrice }}
            {{ rcSku.rcBillingProduct.currentPrice.currency }}
          </span>
          <span *ngIf="!onlineOffline">Offline</span>
        </ion-button>

        <!-- rune purchase is different -->
        <!-- mobile product -->
        <ion-button size="small" (click)="purchase(rcSku, product.id)" *ngIf="rcSku && !owned && category == 'rune' && !web" fill="outline" class="xsmall" [disabled]="!onlineOffline">
          <span *ngIf="onlineOffline">{{ rcSku.product.price | number:'1.0-2' | currency: rcSku.product.currencyCode }}</span>
          <span *ngIf="!onlineOffline">Offline</span>
        </ion-button>
        <!-- web product  -->
        <ion-button size="small" (click)="purchase(rcSku, product.id)" *ngIf="rcSku && !owned && category == 'rune' && web" fill="outline" class="xsmall" [disabled]="!onlineOffline">
          <span *ngIf="onlineOffline">
            {{ rcSku.rcBillingProduct.currentPrice.formattedPrice }}
            {{ rcSku.rcBillingProduct.currentPrice.currency }}
          </span>
          <span *ngIf="!onlineOffline">Offline</span>
        </ion-button>

        <ion-button size="small" (click)="openBrowser(product.url)" *ngIf="item && owned" class="xsmall" fill="outline">
          Buy Physical
        </ion-button>
        <ion-button fill="outline" size="small" (click)="navigate(product.url)" class="xsmall">
          More Info
        </ion-button>
      </div>
    </ion-grid>
  </div>
</div>

<ion-item *ngIf="product && productType == 'list'" class="product-list" (click)="checkViewOnClick(product.id)" [disabled]="web && !product.web">
  <app-deck *ngIf="category != 'rune'" [product]="product" [view]="['front-back']" [height]="'100%'"></app-deck>
  <app-deck *ngIf="category == 'rune'" [product]="product" [view]="['small']" [height]="'100%'"></app-deck>

  <ion-label *ngIf="!web || product.web">
    <p><strong>{{product.name}}</strong></p>
    <p class="author" *ngIf="product.creator">
      <em>by</em> {{product.creator}} <span *ngIf="product.type">· {{product.type.replace('Tradition', '')}}</span>
    </p>
  </ion-label>

  <ion-label *ngIf="web && !product.web">
    <p><strong>{{product.name}}</strong></p>
    <p class="author" *ngIf="product.creator">
      <em>Currently Unavailble on Web for Licensing Reasons</em>
    </p>
  </ion-label>

  <div *ngIf="category == 'tarot'">
    <ion-chip *ngIf="!product.reversals" class="reversals danger" slot="end">
      <ion-icon name="arrow-up-circle-outline"></ion-icon>
      Upright Only
    </ion-chip>
    <ion-chip *ngIf="product.reversals" class="reversals" slot="end">
      <ion-icon name="swap-vertical-outline"></ion-icon>
      Reversals
    </ion-chip>
  </div>
  <ion-chip *ngIf="category != 'tarot'" class="reversals" slot="end">
    {{category}}
  </ion-chip>
  <ion-radio *ngIf="radio" slot="end" [value]="product.id" mode="md"></ion-radio>
</ion-item>

<!-- This is only for default decks, so it's fine to use straight up images for once. -->
<ion-card class="product-shop deck-preview" *ngIf="product && productType == 'card'">
  <app-deck [product]="product" [view]="['front-back']" [height]="'250px'"></app-deck>
  <ion-card-header>
    <ion-card-title>{{product.name}}</ion-card-title>
    <ion-card-subtitle mode="md" *ngIf="product.creator">
      <em>by</em> {{product.creator}}
    </ion-card-subtitle>

    <div *ngIf="category == 'tarot'">
      <ion-chip *ngIf="!product.reversals" class="reversals danger">
        <ion-icon name="arrow-up-circle-outline"></ion-icon>
        Upright Only
      </ion-chip>
      <ion-chip *ngIf="product.reversals" class="reversals">
        <ion-icon name="swap-vertical-outline"></ion-icon>
        Reversals
      </ion-chip>
    </div>
  </ion-card-header>
</ion-card>
