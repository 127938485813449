import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class WebGuard implements CanActivate {
  constructor(
    private router: Router
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Block route if on mobile
    if (Capacitor.getPlatform() != 'web') {
      this.router.navigateByUrl('/tabs/tab-reading');
      return false;
    } else {
      return true;
    }
  }
}
