import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { WebGuard } from './guards/web.guard';

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },

  // public routes - web only
  { path: 'home', canActivate: [WebGuard], loadChildren: () => import('./home/home.module').then( m => m.HomePageModule), data: { isPublic: true }},
  { path: 'reading/:id/:deck/:cards', canActivate: [WebGuard], loadChildren: () => import('./home/reading/reading.module').then( m => m.ReadingPageModule), data: { isPublic: true }},
  { path: 'pricing', canActivate: [WebGuard], loadChildren: () => import('./home/pricing/pricing.module').then( m => m.PricingPageModule), data: { isPublic: true }},

  // public routes - both web and mobile
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule) },
  { path: 'forgot', loadChildren: () => import('./auth/forgot/forgot.module').then(m => m.ForgotPageModule) },
  { path: 'about', loadChildren: () => import('./menu-items/about/about.module').then(m => m.AboutPageModule) },

  // Lazy Loaded Route (Page)
  { path: 'get-reading/:id', canActivate: [AuthGuard], loadChildren: () => import('./tab-reading/get-reading/get-reading.module').then(m => m.GetReadingPageModule) },
  { path: 'input-reading/:id', canActivate: [AuthGuard], loadChildren: () => import('./tab-reading/input-reading/input-reading.module').then(m => m.InputReadingPageModule) },
  { path: 'lesson/:id', canActivate: [AuthGuard], loadChildren: () => import('./tab-learn/lesson/lesson.module').then(m => m.LessonPageModule) },
  { path: 'lesson-complete/:id', canActivate: [AuthGuard], loadChildren: () => import('./tab-learn/lesson-complete/lesson-complete.module').then(m => m.LessonCompletePageModule) },
  { path: 'scene/:id', canActivate: [AuthGuard], loadChildren: () => import('./scene/scene.module').then(m => m.ScenePageModule) },
  { path: 'new-spread/:id', canActivate: [AuthGuard], loadChildren: () => import('./tab-reading/new-spread/new-spread.module').then(m => m.NewSpreadPageModule) },
  { path: 'new-spread', canActivate: [AuthGuard], loadChildren: () => import('./tab-reading/new-spread/new-spread.module').then(m => m.NewSpreadPageModule) },
  { path: 'mirror', canActivate: [AuthGuard], loadChildren: () => import('./tab-journal/mirror/mirror.module').then(m => m.MirrorPageModule) },
  { path: 'mirror/monthly/:year/:month', canActivate: [AuthGuard], loadChildren: () => import('./tab-journal/mirror/monthly/monthly.module').then(m => m.MonthlyPageModule) },
  { path: 'calendar/:type', canActivate: [AuthGuard], loadChildren: () => import('./tab-journal/calendar/calendar.module').then(m => m.CalendarPageModule) },
  { path: 'free-form-digital', canActivate: [AuthGuard], loadChildren: () => import('./tab-spreads/free-form/free-form.module').then(m => m.FreeFormPageModule) },
  { path: 'free-form-physical', canActivate: [AuthGuard], loadChildren: () => import('./tab-spreads/free-form-physical/free-form-physical.module').then( m => m.FreeFormPhysicalPageModule) },
  { path: 'preview-deck/:type/:deck', canActivate: [AuthGuard], loadChildren: () => import('./tab-shop/preview-deck/preview-deck.module').then( m => m.PreviewDeckPageModule)},
  { path: 'preview-reading/:id', canActivate: [AuthGuard], loadChildren: () => import('./tab-reading/preview-reading/preview-reading.module').then( m => m.PreviewReadingPageModule)},
  { path: 'journal-shop', canActivate: [AuthGuard], loadChildren: () => import('./modals/journal-shop/journal-shop.module').then( m => m.JournalShopPageModule)},

  // Lazy Loaded Route (Menu Pages)
  { path: 'password', canActivate: [AuthGuard], loadChildren: () => import('./menu-items/password/password.module').then(m => m.PasswordPageModule) },
  { path: 'delete', canActivate: [AuthGuard], loadChildren: () => import('./menu-items/delete/delete.module').then(m => m.DeletePageModule) },
  { path: 'account/:open', canActivate: [AuthGuard], loadChildren: () => import('./menu-items/account/account.module').then(m => m.AccountPageModule)},
  { path: 'profile', canActivate: [AuthGuard], loadChildren: () => import('./menu-items/profile/profile.module').then( m => m.ProfilePageModule)},

  // Lazy Loaded Route (Modals)
  { path: 'view-reading', canActivate: [AuthGuard], loadChildren: () => import('./modals/view-reading/view-reading.module').then(m => m.ViewReadingPageModule) },
  { path: 'card-modal', canActivate: [AuthGuard], loadChildren: () => import('./modals/card-modal/card-modal.module').then(m => m.CardModalPageModule) },
  { path: 'select-modal', canActivate: [AuthGuard], loadChildren: () => import('./modals/select-modal/select-modal.module').then(m => m.SelectModalPageModule) },
  { path: 'save', canActivate: [AuthGuard], loadChildren: () => import('./modals/save-reading/save-reading.module').then(m => m.SaveReadingPageModule) },
  { path: 'interpret', canActivate: [AuthGuard], loadChildren: () => import('./modals/interpret/interpret.module').then(m => m.InterpretPageModule) },
  { path: 'edit-spread-position', canActivate: [AuthGuard], loadChildren: () => import('./modals/edit-spread-position/edit-spread-position.module').then(m => m.EditSpreadPositionPageModule) },
  { path: 'list-positions', canActivate: [AuthGuard], loadChildren: () => import('./modals/list-positions/list-positions.module').then(m => m.ListPositionsPageModule) },
  { path: 'save-spread', canActivate: [AuthGuard], loadChildren: () => import('./modals/save-spread/save-spread.module').then(m => m.SaveSpreadPageModule) },
  { path: 'article/:id', canActivate: [AuthGuard], loadChildren: () => import('./modals/article/article.module').then(m => m.ArticlePageModule) },
  { path: 'notification', canActivate: [AuthGuard], loadChildren: () => import('./modals/notification/notification.module').then(m => m.NotificationPageModule) },
  { path: 'default-settings', canActivate: [AuthGuard], loadChildren: () => import('./modals/default-settings/default-settings.module').then(m => m.DefaultSettingsPageModule) },
  { path: 'spread-creator', canActivate: [AuthGuard], loadChildren: () => import('./tab-spreads/spread-creator/spread-creator.module').then(m => m.SpreadCreatorPageModule)},
  { path: 'report', canActivate: [AuthGuard], loadChildren: () => import('./modals/report/report.module').then(m => m.ReportPageModule)},
  { path: 'catssandra', canActivate: [AuthGuard], loadChildren: () => import('./modals/catssandra/catssandra.module').then(m => m.CatssandraPageModule)},
  { path: 'filter-cards', canActivate: [AuthGuard], loadChildren: () => import('./modals/filter-cards/filter-cards.module').then(m => m.FilterCardsPageModule)},
  { path: 'digital-shop', canActivate: [AuthGuard], loadChildren: () => import('./modals/digital-shop/digital-shop.module').then(m => m.DigitalShopPageModule)},
  { path: 'loading', canActivate: [AuthGuard], loadChildren: () => import('./modals/loading/loading.module').then( m => m.LoadingPageModule)},
  { path: 'choose-deck', canActivate: [AuthGuard], loadChildren: () => import('./modals/choose-deck/choose-deck.module').then( m => m.ChooseDeckPageModule)},
  { path: 'screenshot', canActivate: [AuthGuard], loadChildren: () => import('./modals/screenshot/screenshot.module').then( m => m.ScreenshotPageModule)},
  { path: 'deal', canActivate: [AuthGuard], loadChildren: () => import('./modals/deal/deal.module').then( m => m.DealPageModule)},
  { path: 'ai-contacts', canActivate: [AuthGuard], loadChildren: () => import('./modals/ai-contacts/ai-contacts.module').then( m => m.AiContactsPageModule)},
  { path: 'call', canActivate: [AuthGuard], loadChildren: () => import('./modals/call/call.module').then( m => m.CallPageModule)},
  { path: 'call-complete', canActivate: [AuthGuard], loadChildren: () => import('./modals/call-complete/call-complete.module').then( m => m.CallCompletePageModule)},

  // public modals
  { path: 'signup', loadChildren: () => import('./modals/signup/signup.module').then( m => m.SignupPageModule)},

  // 404 should always come last - would never come up for mobile, only for desktop
  { path: '**', redirectTo: '/home', pathMatch: 'full' },

  // Redirect
  // { path: 'here', redirectTo: 'there', pathMatch: 'full' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
